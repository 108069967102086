//AGREGADO PARA SEARCH
$(window).click(function() {
	if($('#header__menu .sub-menu').is(':visible')){
		$('#header__menu .sub-menu').stop().slideUp();
	}
});

$('#header__menu .sub-menu').click(function(event){
	event.stopPropagation();
});

$('.menu-discover').click(function(event){
	event.preventDefault();
	event.stopPropagation();
	$(this).find('.sub-menu').stop().slideDown();
});
