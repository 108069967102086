( function( $ ) {

	$('#contact-select-accommodations').each(function(){
		var $this = $(this), numberOfOptions = $(this).children('option').length;

		$this.addClass('select-hidden');
		$this.wrap('<div class="select"></div>');
		$this.after('<div class="select-styled"></div>');

		var $styledSelect = $this.next('div.select-styled');
		$styledSelect.text($this.children('option').eq(0).text());

		var $list = $('<ul />', {'class': 'select-options'}).insertAfter($styledSelect);

		for (var i = 0; i < numberOfOptions; i++) {
			var $item = $('<li />', {rel: $this.children('option').eq(i).val()});
			$('<span/>',{text: $this.children('option').eq(i).text().trim()}).appendTo($item);
			$item.appendTo($list);
		}

		var $listItems = $list.children('li');

		$styledSelect.click(function(e) {
			e.stopPropagation();
			$('div.select-styled.active').not(this).each(function(){
				$(this).removeClass('active').next('ul.select-options').hide();
			});
			$(this).toggleClass('active').next('ul.select-options').toggle();
		});

		$listItems.click(function(e) {
			e.stopPropagation();
			$styledSelect.text($(this).text()).removeClass('active');
			$this.val($(this).attr('rel'));
			$list.hide();
			//console.log($this.val());
			setAccommodation($this.val());
		});

		$(document).click(function() {
			$styledSelect.removeClass('active');
			$list.hide();
		});

	});


	$(window).on('load', () => {
		const url = new URL(window.location.href);
		const accommodation = url.searchParams.get("a");
		if (accommodation){
			setAccommodation(accommodation);
			var $legacySelect = $('#contact-select-accommodations');
			var $styledSelect = $legacySelect.next('div.select-styled');
			$styledSelect.text($legacySelect.find('option[value="' + accommodation + '"]').text());
			$legacySelect.val(accommodation);
		}
	});

	function setAccommodation(accommodation) {
		$('#contact-form-accommodation').addClass('d-block').removeClass('d-none');
		$('input[name=contact-accommodation]').val(accommodation);
		ajaxContactFormAccommodation( accommodation );
		//console.log(accommodation);
	}

	function ajaxContactFormAccommodation( accommodation ) {
		$.ajax( {
			type: 'POST',
			url: wp_js_var.ajax_url,
			dataType: 'json',
			data: {
				action: 'contact_accommodations',
				accommodation,

			},
			success( response ) {
				if (response.phone){
					$( '#contact-cta-phone' ).addClass('d-block').removeClass('d-none');
					$( '#contact-cta-phone a' ).html( response.phone );
				}
				if (!response.phone){
					$( '#contact-cta-phone' ).addClass('d-none').removeClass('d-block');
				}
				//console.log(response)
			},
			error( err ) {
				console.error( err );
			},
		} );
	}

}( jQuery ) );
