( function( $ ) {

	$('#budget-select-accommodations').each(function(){
		var $this = $(this), numberOfOptions = $(this).children('option').length;

		$this.addClass('select-hidden');
		$this.wrap('<div class="select"></div>');
		$this.after('<div class="select-styled"></div>');

		var $styledSelect = $this.next('div.select-styled');
		$styledSelect.text($this.children('option').eq(0).text());

		var $list = $('<ul />', {'class': 'select-options'}).insertAfter($styledSelect);

		for (var i = 0; i < numberOfOptions; i++) {
			var $item = $('<li />', {rel: $this.children('option').eq(i).val()});
			$('<span/>',{text: $this.children('option').eq(i).text().trim()}).appendTo($item);
			$item.appendTo($list);
		}

		var $listItems = $list.children('li');

		$styledSelect.click(function(e) {
			e.stopPropagation();
			$('div.select-styled.active').not(this).each(function(){
				$(this).removeClass('active').next('ul.select-options').hide();
			});
			$(this).toggleClass('active').next('ul.select-options').toggle();
		});

		$listItems.click(function(e) {
			e.stopPropagation();
			$styledSelect.text($(this).text()).removeClass('active');
			$this.val($(this).attr('rel'));
			$list.hide();
			setAccommodation($this.val());
		});

		$(document).click(function() {
			$styledSelect.removeClass('active');
			$list.hide();
		});

	});

	$(window).on('load', () => {
		const url = new URL(window.location.href);
		const accommodation = url.searchParams.get("a");
		const hall = url.searchParams.get("h");
		if (accommodation){
			var $legacySelect = $('#budget-select-accommodations');
			var $styledSelect = $legacySelect.next('div.select-styled');
			$styledSelect.text($legacySelect.find('option[value="' + accommodation + '"]').text());
			$legacySelect.val(accommodation);
		}
		if (accommodation && hall === null){
			setAccommodation(accommodation);
			//console.log('only accommodation' + accommodation)
		}
		if (hall && accommodation){
			//console.log('hall && accommodation' + hall)
			setAccommodation(accommodation, hall);
		}
	});

	function setAccommodation(accommodation, hall) {
		$('#contact-form-accommodation').addClass('d-block').removeClass('d-none');
		$('input[name=contact-accommodation]').val(accommodation);
		ajaxContactFormAccommodation( accommodation );
		ajaxBudgetFormAccommodation( accommodation, hall );
		//console.log(accommodation);
	}

	function ajaxContactFormAccommodation( accommodation ) {
		$.ajax( {
			type: 'POST',
			url: wp_js_var.ajax_url,
			dataType: 'json',
			data: {
				action: 'contact_accommodations',
				accommodation,

			},
			success( response ) {
				if (response.phone){
					$( '#contact-cta-phone' ).addClass('d-block').removeClass('d-none');
					$( '#contact-cta-phone a' ).html( response.phone );
				}
				if (!response.phone){
					$( '#contact-cta-phone' ).addClass('d-none').removeClass('d-block');
				}
				//console.log(response)
			},
			error( err ) {
				console.error( err );
			},
		} );
	}

	function ajaxBudgetFormAccommodation( accommodation, hall ) {
		$.ajax( {
			type: 'POST',
			url: wp_js_var.ajax_url,
			dataType: 'json',
			data: {
				action: 'budget_halls',
				accommodation,

			},
			success( response ) {
				if (response){
					var $this = $( '#budget-select-hall' );

					$this.addClass('select-hidden').empty();
					$this.wrap('<div class="select"></div>');
					$this.after('<div class="select-styled"></div>');

					var $styledSelect = $this.next('div.select-styled');
					$styledSelect.text($this.children('option').eq(0).text());

					var $list = $('<ul />', {'class': 'select-options'}).insertAfter($styledSelect);

					for (const key in response){
						if(response.hasOwnProperty(key)){

							var $item = $('<li />', {rel: key});
							$('<span/>',{text: response[key]}).appendTo($item);
							$item.appendTo($list);

							var $option = $('<option />', {value: key, text: response[key]});
							$option.appendTo($this);

						}
					}

					if (hall){
						$styledSelect.text(response[hall]);
						$this.val(hall);
					}

					var $listItems = $list.children('li');

					$styledSelect.click(function(e) {
						e.stopPropagation();
						$('div.select-styled.active').not(this).each(function(){
							$(this).removeClass('active').next('ul.select-options').hide();
						});
						$(this).toggleClass('active').next('ul.select-options').toggle();
					});

					$listItems.click(function(e) {
						e.stopPropagation();
						$styledSelect.text($(this).text()).removeClass('active');
						$this.val($(this).attr('rel'));
						$list.hide();
						//console.log($this.val());

					});

					$(document).click(function() {
						$styledSelect.removeClass('active');
						$list.hide();
					});

				}
				//console.log(response)
			},
			error( err ) {
				console.error( err );
			},
		} );
	}

}( jQuery ) );
