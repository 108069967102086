$('#sidebar-trigger').click(function(event){
	event.preventDefault();
	$('.overlay').stop().addClass('overlay--active');
	$('#fullsidebar').stop().addClass('fullsidebar--active');
});

$('#hotelsidebar-trigger').click(function(event){
	event.preventDefault();
	$('.overlay').stop().addClass('overlay--active');
	$('#hotelsidebar').stop().addClass('fullsidebar--active');
});

// revisar casos
$('.overlay').click(function(event){
	event.preventDefault();
	$('.overlay').stop().removeClass('overlay--active');
	$('.fullsidebar').stop().removeClass('fullsidebar--active');
	$('.modal.modal--active').stop().removeClass('modal--active');
});

$('.fullsidebar__close').click(function(event){
	event.preventDefault()
	$('.overlay').stop().removeClass('overlay--active');
	$('.fullsidebar').stop().removeClass('fullsidebar--active');
});

$('.faqs_fullsidebar__close').click(function(event){
	event.preventDefault()
	$('.overlay').stop().removeClass('overlay--active');
	$('.fullsidebar').stop().removeClass('fullsidebar--active');
});

$(document).scroll(function() {
	var y = $(this).scrollTop();
	if (y > 300) {
		$('.header--single').fadeIn();
	} else {
		$('.header--single').fadeOut();
	}
});

$('.fullsidebar__menu .menu-item-has-children').click(function(event){
	event.preventDefault();
	event.stopPropagation();
	$(this).find('.sub-menu').stop().slideToggle();
});

$('#fullsidebar__menu .sub-menu').click(function(event){
	event.stopPropagation();
});
