$('#hero-reservation-select').each(function(){
	var $this = $(this), numberOfOptions = $(this).children('option').length;
	const url = new URL(window.location.href);

	$this.addClass('select-hidden');
	$this.wrap('<div class="select"></div>');
	$this.after('<div class="select-styled"></div>');

	var $styledSelect = $this.next('div.select-styled');
	$styledSelect.text($this.children('option').eq(0).text());

	var $list = $('<ul />', {'class': 'select-options'}).insertAfter($styledSelect);

	for (var i = 0; i < numberOfOptions; i++) {

		var $item = $('<li />', {rel: $this.children('option').eq(i).val()});
		$('<span/>',{text: $this.children('option').eq(i).text().trim()}).appendTo($item);
		$item.appendTo($list);

		if ($this.children('option').eq(i).val() === url.href){
			$styledSelect.text($this.children('option').eq(i).text());
		}

		//$('<li />', {text: $this.children('option').eq(i).text(), rel: $this.children('option').eq(i).val()}).appendTo($list);

	}

	var $listItems = $list.children('li');

	$styledSelect.click(function(e) {
		e.stopPropagation();
		$('div.select-styled.active').not(this).each(function(){
			$(this).removeClass('active').next('ul.select-options').hide();
		});
		$(this).toggleClass('active').next('ul.select-options').toggle();
	});

	$listItems.click(function(e) {
		e.stopPropagation();
		$styledSelect.text($(this).text()).removeClass('active');
		$this.val($(this).attr('rel'));
		$list.hide();
		//console.log($this.val());
		window.location = $(this).attr('rel');
	});

	$(document).click(function() {
		$styledSelect.removeClass('active');
		$list.hide();
	});

});

$('.hero .select-styled').click(function() {
	if($('.hero .select-options').is(':visible')){
		$('.hero__overlay').stop().fadeIn();
	}else{
		$('.hero__overlay').stop().fadeOut();
	}
});

// Select
$('.hero__select .select-styled').on('DOMSubtreeModified', function(){
	$('.hero__select .select-styled').addClass('changed');
});
// Select

// Search

//Calendar Strings

//console.log(wp_js_var.page_lang)

var closeTextES = 'Cerrar'
var prevTextES = 'Anterior'
var nextTextES = 'Siguiente'
var currentTextES = 'Hoy'
var monthNamesES = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
var monthNamesShortES = ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic']
var dayNamesES = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
var dayNamesShortES = ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb']
var dayNamesMinES = ['Do','Lu','Ma','Mi','Ju','Vi','Sá']
var weekHeaderES = 'Sm'

var closeTextEN = 'Close'
var prevTextEN = 'Previous'
var nextTextEN = 'Next'
var currentTextEN = 'Today'
var monthNamesEN = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
var monthNamesShortEN = ['Jan','Feb','Mar','Apr', 'May','Jun','Jul','Aug','Sep', 'Oct','Nov','Dec']
var dayNamesEN = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
var dayNamesShortEN = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
var dayNamesMinEN = ['Su','Mo','Tu','We','Th','Fr','Sa']
var weekHeaderEN = 'Wk'

var closeTextFR = 'Fermer'
var prevTextFR = 'Préc'
var nextTextFR = 'Suiv'
var currentTextFR = "Courant"
var monthNamesFR = [ "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre" ]
var monthNamesShortFR = [ "Janv.", "Févr.", "Mars", "Avril", "Mai", "Juin", "Juil.", "Août", "Sept.", "Oct.", "Nov.", "Déc." ]
var dayNamesFR = [ "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi" ]
var dayNamesShortFR = [ "Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam." ]
var dayNamesMinFR = [ "D", "L", "M", "M", "J", "V", "S" ]
var weekHeaderFR = 'Sm'

var closeText = '';
var prevText = '';
var nextText = ''
var currentText = ''
var monthNames = []
var monthNamesShort = []
var dayNames = []
var dayNamesShort = []
var dayNamesMin = []
var weekHeader = ''

switch (wp_js_var.page_lang) {
	case 'en':
		closeText = closeTextEN;
		prevText = prevTextEN;
		nextText = nextTextEN
		currentText = currentTextEN
		monthNames = monthNamesEN
		monthNamesShort = monthNamesShortEN
		dayNames = dayNamesEN
		dayNamesShort = dayNamesShortEN
		dayNamesMin = dayNamesMinEN
		weekHeader = weekHeaderEN
		break;
	case 'fr':
		closeText = closeTextFR;
		prevText = prevTextFR;
		nextText = nextTextFR
		currentText = currentTextFR
		monthNames = monthNamesFR
		monthNamesShort = monthNamesShortFR
		dayNames = dayNamesFR
		dayNamesShort = dayNamesShortFR
		dayNamesMin = dayNamesMinFR
		weekHeader = weekHeaderFR
		break;
	case 'es':
	default:
		closeText = closeTextES;
		prevText = prevTextES;
		nextText = nextTextES
		currentText = currentTextES
		monthNames = monthNamesES
		monthNamesShort = monthNamesShortES
		dayNames = dayNamesES
		dayNamesShort = dayNamesShortES
		dayNamesMin = dayNamesMinES
		weekHeader = weekHeaderES
		break;
}


// Calendar

$(function(){
	$(".inputdate").datepicker({
		numberOfMonths: 1,
		closeText: closeText,
		prevText: prevText,
		nextText: nextText,
		currentText: currentText,
		monthNames: monthNames,
		monthNamesShort: monthNamesShort,
		dayNames: dayNames,
		dayNamesShort: dayNamesShort,
		dayNamesMin: dayNamesMin,
		weekHeader: weekHeader,
		dateFormat: 'dd/mm/yy',
		firstDay: 1
	});
});


$(function(){
	$("#search-dates").datepicker({
		numberOfMonths: 2,
		closeText: closeText,
		prevText: prevText,
		nextText: nextText,
		currentText: currentText,
		monthNames: monthNames,
		monthNamesShort: monthNamesShort,
		dayNames: dayNames,
		dayNamesShort: dayNamesShort,
		dayNamesMin: dayNamesMin,
		weekHeader: weekHeader,
		dateFormat: 'dd/mm/yy',
		firstDay: 1,
		onSelect: function( selectedDate ) {
			if(!$(this).data().datepicker.first){
				$(this).data().datepicker.inline = true
				$(this).data().datepicker.first = selectedDate;
			}else{
				let firstDateFormated = $(this).data().datepicker.first.split("/").reverse().join("-");
				let currentDateFormated = selectedDate.split("/").reverse().join("-");
				if(currentDateFormated > firstDateFormated){
					$(this).val($(this).data().datepicker.first+" - "+selectedDate);
				}else{
					$(this).val(selectedDate+" - "+$(this).data().datepicker.first);
				}
				$(this).data().datepicker.inline = false;
				updateURL()
			}
			var places = $('#search-places').outerWidth();
			var dates = $('#search-dates').outerWidth();
			var size = $('#search-size').outerWidth();
			var width = places + dates + size;

			setTimeout(function() {
				$('#ui-datepicker-div').css({
					'width': width
				});
			}, 2);
		},
		onClose:function(){
			delete $(this).data().datepicker.first;
			$(this).data().datepicker.inline = false;
		},
		onChangeMonthYear: function(input, inst) {
			var places = $('#search-places').outerWidth();
			var dates = $('#search-dates').outerWidth();
			var size = $('#search-size').outerWidth();
			var width = places + dates + size;

			setTimeout(function() {
				$('#ui-datepicker-div').css({
					'width': width
				});
			}, 2);
		},
		beforeShow: function(input, inst) {
			var $this = $(this);
			var cal = inst.dpDiv;
			var width = 950;
			var top = $this.offset().top + $this.outerHeight();
			var left = $('#search-places').offset().left;
			var places = $('#search-places').outerWidth();
			var dates = $('#search-dates').outerWidth();
			var size = $('#search-size').outerWidth();
			width = places + dates + size;
			//console.log(width);

			setTimeout(function() {
				cal.css({
					'width': width,
					'top': top,
					'left': left
				});
			}, 10);
		}
	});
});
// Calendar

//AGREGADO PARA SEARCH
$(window).click(function() {
	if($('.search__item__places').is(':visible')){
		$('.search__item__places').stop().slideUp();
	}
	if($('.search__item__size').is(':visible')){
		$('.search__item__size').stop().slideUp();
	}
	if(!$('.hero .select-options').is(':visible')){
		$('.hero__overlay').stop().fadeOut();
	}
});

$('.search__item__places').click(function(event){
	event.stopPropagation();
});

$('.search__item__size').click(function(event){
	event.stopPropagation();
});

// Search
$("#search-size").click(function(){
	let self = $(this).parent();
	$('.search__item__places').stop().slideUp();
	self.find('.search__overlay:first').stop().slideToggle();
	event.stopPropagation();
});
$("#search-places").click(function(){
	let self = $(this).parent();
	$('.search__item__size').stop().slideUp();
	self.find('.search__overlay:first').stop().slideToggle();
	event.stopPropagation();
});
$('.search__overlay__close').click(function(event){
	let self = $(this).parent();
	event.preventDefault();
	self.stop().slideUp();
});
// Search


//Places
$('.search__places__item').click(function(event){

	event.preventDefault();
	let input = $('#search-places')[0];
	let id = $(this)[0].dataset.hotel_id;
	let guide = $(this)[0].dataset.guide_id;
	let name = $(this)[0].dataset.name;

	input.value = name;
	input.dataset.selected = id;
	input.dataset.guide = guide;

	if($('.search__item__places').is(':visible')){
		$('.search__item__places').stop().slideUp();
	}
	updateURL();
})
//Places



//COUNTERS
$('.search__button--plus').click(function(event){
	event.preventDefault();
	let self = $(this);
	let parent = self.parent().parent();
	parent[0].dataset.count = parseInt(parent[0].dataset.count) + 1;
	parent.find('.search__item__size__number').text(parent[0].dataset.count);
	if (parent[0].dataset.count > 0 && parent.find('.search__button--minus').hasClass('disabled')) {
		parent.find('.search__button--minus').removeClass('disabled');
	}
	updateSizeInput();
})
$('.search__button--minus').click(function(event){
	event.preventDefault();
	let self = $(this);
	let parent = self.parent().parent();
	let item = "";
	if (parent.hasClass('search__counter__adults')) {
		item = "adults";
	}
	if (parent.hasClass('search__counter__children')) {
		item = "children";
	}
	if (parent.hasClass('search__counter__rooms')) {
		item = "rooms";
	}
	if (item == "adults" || item == "rooms") {
		if (parent[0].dataset.count > 1) {
			if (parent[0].dataset.count == 2) {
				self.addClass('disabled');
			}
			parent[0].dataset.count = parseInt(parent[0].dataset.count) - 1;
			parent.find('.search__item__size__number').text(parent[0].dataset.count);
			updateSizeInput();
		}
	}else{
		if (parent[0].dataset.count > 0) {
			if (parent[0].dataset.count == 1) {
				self.addClass('disabled');
			}
			parent[0].dataset.count = parseInt(parent[0].dataset.count) - 1;
			parent.find('.search__item__size__number').text(parent[0].dataset.count);
			updateSizeInput();
		}
	}

})
function updateSizeInput(){
	let parent = $('#search-size').parent();
	let adultCount = parent.find('.search__counter__adults')[0].dataset.count;
	let childrenCount = parent.find('.search__counter__children')[0].dataset.count;
	let roomCount = parent.find('.search__counter__rooms')[0].dataset.count;


	let stringES = adultCount+" Adultos · "+childrenCount+" Niños · "+roomCount+" hab";
	let stringEN = adultCount+" Adults · "+childrenCount+" Kids · "+roomCount+" room";
	let stringFR = adultCount+" Adultes · "+childrenCount+" Enfants · "+roomCount+" chamb";

	let string = ""

	switch (wp_js_var.page_lang) {
		case 'en':
			string = stringEN
			break;
		case 'fr':
			string = stringFR
			break;
		case 'en':
		default:
			string = stringES
			break;
	}



	$('#search-size').attr("placeholder", string);
	updateURL();
}
//COUNTERS


function updateURL(){

	let url_item = $('.search__item__submit');
	let url_string = "";
	let location = wp_js_var.booking_page;
	let acc_id = $('#search-places')[0].dataset.selected;
	let guide_id = $('#search-places')[0].dataset.guide;
	let dates_value = $('#search-dates')[0].value;
	let date_in = "";
	let date_out = "";
	if (dates_value) {
		date_in = dates_value.split(" - ")[0].split("/").reverse().join("-");
		date_out = dates_value.split(" - ")[1].split("/").reverse().join("-");
	}
	let adult_count = $('.search__counter__adults')[0].dataset.count;
	let child_count = $('.search__counter__children')[0].dataset.count;
	let room_count = $('.search__counter__rooms')[0].dataset.count;

	url_string = location + "?action=c_1&hotel_id="+acc_id+"&guide_id="+guide_id+"&date_in="+date_in+"&date_out="+date_out+"&adults="+adult_count+"&children="+child_count+"&rms="+room_count;

	if (acc_id != "" && guide_id != "" && date_in != "" && date_out != "" && adult_count > 0 && child_count >= 0 && room_count > 0) {
		url_item[0].href=url_string;
		url_item.removeClass("disabled");
	}

}
