$('.text--trigger').click(function(event){
	let accordion = $(this);
	if ($(window).width() < 768) {
		accordion.toggleClass('active');
		accordion.next().stop().slideToggle();
	}
});

$('.footer__trigger').click(function(event){
	let self = $(this);
	let footeritem = self.parent();
	footeritem.toggleClass('footer__item--active');
	footeritem.find('ul:first').stop().slideToggle();
	event.preventDefault();
});
