// Show
$('.show-more').click(function(event){
	event.preventDefault();
	let self = $(this);
	self.parents('.show').addClass('show--active');
	self.remove();
});
$('.show-list-more').click(function(event){
	event.preventDefault();
	let self = $(this);
	self.parents('.show').find('.show__hide').removeClass('show__hide');
	self.remove();
});
// Show
