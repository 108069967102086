$(function(){
	$('.hero__deal__slider').slick({
		arrows: false,
		infinite: true,
		slidesToShow: 2,
		responsive: [
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
});
