// Switch
$('.tabs__list a').click(function(event){
	let self = $(this);
	let tabid = "#" + self.attr('data-href');
	let tabparent = self.parents('.tabs');
	event.preventDefault();
	tabparent.find('.tabs__list:first').find('.active').removeClass('active');
	tabparent.find('.tabs__content:first').find('.active').removeClass('active');
	self.addClass('active');
	$(tabid).addClass('active');
});

$('.switch__group__single').click(function(event){
	let self = $(this);
	let switchclass = "." + self.attr('data-href');
	let switchparent = self.parents('.switch');
	console.log(switchclass);
	event.preventDefault();
	if($('.switch__group__all').hasClass('switch__group__all--active')){
		$('.switch__group__all').removeClass('switch__group__all--active');
		switchparent.find('.switch__content__item').removeClass('switch__content__item--active');
	}
	switchparent.find(switchclass).toggleClass('switch__content__item--active');
	self.toggleClass('switch__group__single--active');
});

$('.switch__group__all').click(function(event){
	let self = $(this);
	let switchparent = self.parents('.switch');
	event.preventDefault();
	if($('.switch__group__all').hasClass('switch__group__all--active')){
		switchparent.find('.switch__content__item').removeClass('switch__content__item--active');
	}else{
		switchparent.find('.switch__content__item').addClass('switch__content__item--active');
		switchparent.find('.switch__group__single--active').removeClass('switch__group__single--active');
	}
	self.toggleClass('switch__group__all--active');
});

$('.switch__list__single').click(function(event){
	let self = $(this);
	let switchid = "#" + self.attr('data-href');
	let switchparent = self.parents('.switch');
	event.preventDefault();
	if($('.switch__list__all').hasClass('switch__list__all--active')){
		$('.switch__list__all').removeClass('switch__list__all--active');
		switchparent.find('.switch__content__item').removeClass('switch__content__item--active');
	}
	self.toggleClass('switch__list__single--active');
	$(switchid).toggleClass('switch__content__item--active');
});

$('.switch__list__all').click(function(event){
	let self = $(this);
	let switchparent = self.parents('.switch');
	event.preventDefault();
	switchparent.find('.switch__list__single.switch__list__single--active').removeClass('switch__list__single--active');
	switchparent.find('.switch__content__item').removeClass('switch__content__item--active');
	if(!self.hasClass('switch__list__all--active')){
		switchparent.find('.switch__content__item').addClass('switch__content__item--active');
	}else{

	}
	$('.switch__list__all').toggleClass('switch__list__all--active');
});
