$('.tabs__list a').click(function(event){
	let self = $(this);
	let tabid = "#" + self.attr('data-href');
	let tabparent = self.parents('.tabs');
	event.preventDefault();
	tabparent.find('.tabs__list:first').find('.active').removeClass('active');
	tabparent.find('.tabs__content:first').find('.active').removeClass('active');
	self.addClass('active');
	$(tabid).addClass('active');
});
