$('.custom-select').each(function(){
	var $this = $(this), numberOfOptions = $(this).children('option').length;

	$this.addClass('select-hidden');
	$this.wrap('<div class="select"></div>');
	$this.after('<div class="select-styled"></div>');

	var $styledSelect = $this.next('div.select-styled');
	$styledSelect.text($this.children('option').eq(0).text());

	var $list = $('<ul />', {'class': 'select-options'}).insertAfter($styledSelect);

	for (var i = 0; i < numberOfOptions; i++) {
		var $item = $('<li />', {rel: $this.children('option').eq(i).val()});
		$('<span/>',{text: $this.children('option').eq(i).text().trim()}).appendTo($item);
		$item.appendTo($list);
		// $('<li />', {text: $this.children('option').eq(i).text(), rel: $this.children('option').eq(i).val()}).appendTo($list);
	}

	var $listItems = $list.children('li');

	$styledSelect.click(function(e) {
		e.stopPropagation();
		$('div.select-styled.active').not(this).each(function(){
			$(this).removeClass('active').next('ul.select-options').hide();
		});
		$(this).toggleClass('active').next('ul.select-options').toggle();
	});

	$listItems.click(function(e) {
		e.stopPropagation();
		$styledSelect.text($(this).text()).removeClass('active');
		$this.val($(this).attr('rel'));
		$list.hide();
		//console.log($this.val());
	});

	$(document).click(function() {
		$styledSelect.removeClass('active');
		$list.hide();
	});

});

$('.custom-select-lang').each(function(){
	var $this = $(this), numberOfOptions = $(this).children('option').length;

	$this.addClass('select-hidden');
	$this.wrap('<div class="select"></div>');
	$this.after('<div class="select-styled"></div>');

	var $styledSelect = $this.next('div.select-styled');
	//$styledSelect.text($this.children('option').eq(0).text());
	$styledSelect.text($this.children('option').eq(0)[0].dataset.display);

	var $list = $('<ul />', {'class': 'select-options'}).insertAfter($styledSelect);

	for (var i = 0; i < numberOfOptions; i++) {
		var $item = $('<li />', {rel: $this.children('option').eq(i).val()});
		$('<span/>',{text: $this.children('option').eq(i).text().trim()}).appendTo($item);
		$item[0].dataset['display'] = $this.children('option').eq(i)[0].dataset.display
		$item.appendTo($list);

		//$('<li />', {text: $this.children('option').eq(i).text(), rel: $this.children('option').eq(i).val()}).appendTo($list);

	}

	var $listItems = $list.children('li');

	$styledSelect.click(function(e) {
		e.stopPropagation();
		$('div.select-styled.active').not(this).each(function(){
			$(this).removeClass('active').next('ul.select-options').hide();
		});
		$(this).toggleClass('active').next('ul.select-options').toggle();
	});

	$listItems.click(function(e) {
		e.stopPropagation();
		//$styledSelect.text($(this).text()).removeClass('active');
		$styledSelect.text($(this)[0].dataset.display).removeClass('active');
		$this.val($(this).attr('rel'));
		$list.hide();
		window.location = $(this).attr('rel');
	});

	$(document).click(function() {
		$styledSelect.removeClass('active');
		$list.hide();
	});

});
