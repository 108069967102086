$('.modal-trigger').click(function(event){
	event.preventDefault();
	let self = $(this);
	let modalid = "#" + self.attr('data-modal');
	$('.overlay').stop().addClass('overlay--active');
	$('.modal').removeClass('modal--active');
	$(modalid).stop().addClass('modal--active');
});

$('.modal__close').click(function(event){
	event.preventDefault();
	let self = $(this);
	let modal = self.parents('.modal');
	modal.stop().removeClass('modal--active');
	$('.overlay').stop().removeClass('overlay--active');
});
